import React from 'react';
import Head from 'next/head';
import Image from 'next/legacy/image';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import { BaseButton } from '../../components/atoms/BaseButton';
import { PartnerLogos } from '../../components/organisms/PartnerLogos';
import { PARTNER_LOGOS } from '../../util/constants';
import { TestimonialCarousel } from '../../components/organisms/TestimonialCarousel';
import testimonials from '../../data/testimonials';

import grandparentPic from '../../images/people/grandparent.jpeg';
import staircasePic from '../../images/marketing/stairs.jpeg';
import accessibleStairsPic from '../../images/marketing/accessible-stairs.jpeg';
import careConversationPic from '../../images/marketing/care-conversation.jpeg';
import brickWallPic from '../../images/marketing/white-brick-wall.jpeg';
import livingRoomPic from '../../images/marketing/living-room.jpeg';
import otPic from '../../images/marketing/ot-care.jpeg';

import styles from './HomePage.module.scss';

export const HomePage: React.FC<React.PropsWithChildren<{}>> = observer(({ children }) => (
  <>
    <Head>
      <title>Welcome to Rosarium Health&trade;</title>
      <meta name="description" content="Local accessible home modification services" />
    </Head>
    <div className={styles.home}>
      {children}
      <section className={styles.hero}>
        <Image
          alt=""
          src={brickWallPic}
          layout="fill"
          objectFit="cover"
          priority
          style={{ zIndex: -1 }}
        />
        <div className={styles.heroContent}>
          <h1>Stay at Home Safely with Affordable Home Accessibility Modifications</h1>
          <p>
            Rosarium Health connects people to healthcare providers and licensed home modification
            contractors to quickly complete key modifications that make every home safe and
            accessible.
          </p>
          <BaseButton href="#signup" round styling="red">
            Join Rosarium Health &trade;
          </BaseButton>
        </div>
        <div className={styles.heroImage}>
          <Image
            src={grandparentPic}
            alt="Multigenerational family"
            layout="fill"
            objectFit="cover"
            priority
            objectPosition="right top"
          />
        </div>
      </section>
      <section className={styles.partners}>
        <h2 className={styles.sectionTitle}>Our Partners</h2>
        <PartnerLogos logos={PARTNER_LOGOS} />
      </section>
      <section className={styles.howItWorks}>
        <h2 className={styles.sectionTitle}>How it works</h2>
        <p className={styles.featuresDescription}>
          Rosarium Health's 3 Step process can quickly make the built environment safe and
          accessible
        </p>
        <section
          className={styles.features}
          aria-labelledby="step1-title"
          data-testid="usage-steps"
        >
          <div className={styles.featuresImage}>
            <Image
              src={staircasePic}
              alt="Non-accessible staircase"
              layout="responsive"
              loading="lazy"
            />
          </div>
          <div className={styles.featuresBody}>
            <h3 id="step1-title" className={styles.sectionSubtitle}>
              Step 1: Describe the Accessibility Needs of the Home
            </h3>
            <p className={styles.featuresDescription}>
              Sign up for a free Rosarium Health account, enter a few details about the home
              modifications needed and connect to a network of clinicians along with licensed home
              modification contractors.
            </p>
          </div>
        </section>
        <section className={styles.features} aria-labelledby="step2-title">
          <div className={styles.featuresBody}>
            <h3 id="step2-title" className={styles.sectionSubtitle}>
              Step 2: Plan a Home Safety Assessment
            </h3>
            <p className={styles.featuresDescription}>
              Once the home modification details are entered, a clinician from a local network will
              connect to schedule a home safety assessment, build a safety and accessibility plan
              and help quickly address the built environment challenges.
            </p>
          </div>
          <div className={styles.featuresImage}>
            <Image
              src={careConversationPic}
              alt="Clinician conversation"
              layout="responsive"
              loading="lazy"
            />
          </div>
        </section>
        <section className={styles.features} aria-labelledby="step3-title">
          <div className={styles.featuresImage}>
            <Image
              src={accessibleStairsPic}
              alt="Accessible staircase"
              layout="responsive"
              loading="lazy"
            />
          </div>
          <div className={styles.featuresBody}>
            <h3 id="step3-title" className={styles.sectionSubtitle}>
              Step 3: Modify the Home
            </h3>
            <p className={styles.featuresDescription}>
              Based on the safety and accessibility plan, licensed home modification contractors
              will provide bids and propose a work schedule for the home modification project.
            </p>
          </div>
        </section>
      </section>
      <section className={styles.testimonial}>
        <div className={styles.testimonialBody}>
          <TestimonialCarousel testimonials={testimonials} />
        </div>
      </section>
      <section className={styles.providerJoin} id="signup">
        <div className={styles.contractorJoin} data-testid="contractor-sign-up">
          <Image
            alt=""
            src={brickWallPic}
            layout="fill"
            objectFit="cover"
            loading="lazy"
            style={{ opacity: '40%' }}
          />
          <div className={styles.providerJoinContent}>
            <h2 className={styles.sectionTitle}>Are you a home modification contractor?</h2>
            <BaseButton href="/contractor" round>
              Join here
            </BaseButton>
          </div>
        </div>
        <div className={styles.clinicianJoin} data-testid="clinician-sign-up">
          <Image
            alt=""
            src={otPic}
            layout="fill"
            objectFit="cover"
            loading="lazy"
            style={{ opacity: '10%' }}
          />
          <div className={styles.providerJoinContent}>
            <h2 className={classNames(styles.sectionTitle, styles.inverted)}>
              Are you a home accessibility clinician?
            </h2>
            <BaseButton href="/clinician" round styling="primary-inverted">
              Join here
            </BaseButton>
          </div>
        </div>
      </section>
    </div>
  </>
));
